import { memo } from 'react'

export default memo<{ width: number; height: number; color?: string; className?: string }>(
  function OrusHorizontalLogo(props) {
    const { width, height, className } = props

    return (
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 274 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.6358 45.5338C63.7925 47.1779 66.3118 48 69.1938 48C70.2435 48 71.236 47.8772 72.1712 47.6315C73.1064 47.4047 74.0416 46.9889 74.9769 46.3842C75.9311 45.7795 76.7805 45.0141 77.5248 44.0881C78.2692 43.1432 78.9372 41.9337 79.5289 40.4596C80.119 39.0171 80.5754 37.3718 80.8983 35.5235C80.9179 35.4112 81.0156 35.3288 81.1301 35.3288C81.269 35.3288 81.3774 35.4483 81.3633 35.5859L80.2325 47.1977C80.193 47.5813 80.4955 47.9147 80.8829 47.9147H93.2754C93.6365 47.9147 93.9292 47.6233 93.9292 47.264V0.650735C93.9292 0.291344 93.6365 0 93.2754 0H81.3279C80.9668 0 80.674 0.291343 80.674 0.650734V17.0523C80.674 17.0851 80.6765 17.1178 80.6815 17.1502L81.9052 25.1477C81.9353 25.3439 81.7827 25.5207 81.5832 25.5207C81.4104 25.5207 81.2679 25.3863 81.2566 25.2146C81.1529 23.6346 80.9396 22.1964 80.6168 20.9001C80.2732 19.5016 79.8533 18.3394 79.3571 17.4134C78.8799 16.4684 78.3074 15.6464 77.6393 14.9471C76.9713 14.2479 76.3033 13.6999 75.6353 13.303C74.9673 12.9061 74.2325 12.5943 73.4309 12.3676C72.6293 12.1219 71.904 11.9612 71.2551 11.8856C70.6062 11.8101 69.9191 11.7723 69.1938 11.7723C66.3309 11.7723 63.8211 12.6038 61.6644 14.2668C59.5268 15.9299 57.914 18.122 56.8261 20.8434C55.7573 23.5647 55.2229 26.5979 55.2229 29.9428C55.2229 33.2878 55.7573 36.3115 56.8261 39.0139C57.8949 41.7164 59.4982 43.8897 61.6358 45.5338ZM80.3305 34.1099C78.9945 34.9981 77.2576 35.4422 75.12 35.4422C73.0015 35.4422 71.2742 34.9981 69.9382 34.1099C68.6212 33.2028 67.9628 31.8893 67.9628 30.1696C67.9628 28.4121 68.6212 27.0892 69.9382 26.201C71.2551 25.3128 72.9824 24.8687 75.12 24.8687C77.2576 24.8687 78.9945 25.3128 80.3305 26.201C81.6665 27.0892 82.3345 28.4121 82.3345 30.1696C82.3345 31.8893 81.6665 33.2028 80.3305 34.1099Z"
          fill="#6D092A"
        />
        <path
          d="M0.644338 44.6918C5.14909 46.8973 10.4597 48 16.5761 48C22.321 48 26.8444 46.8283 30.1463 44.4849C33.4481 42.1227 35.0991 38.5509 35.0991 33.7697C35.0991 32.2956 34.8414 30.9633 34.3261 29.7727C33.8298 28.5822 33.1428 27.5995 32.2648 26.8246C31.4059 26.0309 30.4135 25.3317 29.2874 24.7269C28.1804 24.1222 27.0066 23.6403 25.766 23.2812C24.5445 22.9222 23.3135 22.6103 22.0729 22.3458C20.8514 22.0812 19.6776 21.8544 18.5515 21.6654C17.4446 21.4765 16.4521 21.2969 15.5741 21.1268C14.7153 20.9379 14.0282 20.7205 13.5129 20.4749C13.0166 20.2103 12.7685 19.8985 12.7685 19.5394C12.7685 18.5378 14.1427 18.037 16.8911 18.037C19.7349 18.037 22.5691 18.6323 25.3939 19.8229C28.1429 20.9632 30.5304 22.4614 32.5563 24.3177C32.6638 24.4161 32.8042 24.4718 32.9503 24.4718C33.2674 24.4718 33.5245 24.216 33.5245 23.9004V11.0144C33.5245 10.7972 33.4156 10.5936 33.2327 10.475C28.592 7.46573 23.3929 5.96108 17.6354 5.96108C15.326 5.96108 13.1216 6.24455 11.0221 6.8115C8.94177 7.37844 7.07134 8.20051 5.41087 9.27771C3.76947 10.336 2.45254 11.7156 1.46008 13.4164C0.486692 15.0983 0 16.9881 0 19.0858C0 20.4843 0.238574 21.7694 0.715723 22.9411C1.21196 24.1127 1.87042 25.1049 2.69112 25.9175C3.5309 26.7112 4.4852 27.4388 5.55401 28.1003C6.62282 28.7617 7.73935 29.3003 8.90359 29.716C10.0869 30.1318 11.2703 30.5098 12.4536 30.8499C13.6369 31.1712 14.763 31.4452 15.8318 31.672C16.9006 31.8988 17.8454 32.1161 18.6661 32.324C19.5058 32.513 20.1643 32.7303 20.6415 32.976C21.1377 33.2028 21.3858 33.4484 21.3858 33.713C21.3858 34.1666 21.0423 34.5256 20.3552 34.7902C19.6872 35.0359 18.7615 35.1587 17.5782 35.1587C10.9656 35.1587 5.61956 33.099 1.54014 28.9796C1.4152 28.8535 1.24496 28.7806 1.06698 28.7806H0.940129C0.579023 28.7806 0.286289 29.0719 0.286289 29.4313V44.1146C0.286289 44.3593 0.423777 44.5838 0.644338 44.6918Z"
          fill="#6D092A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.717 48C113.777 48 111.058 47.537 108.557 46.611C106.057 45.6661 103.958 44.381 102.259 42.7558C100.58 41.1305 99.2626 39.2029 98.3083 36.9729C97.354 34.743 96.8768 32.3618 96.8768 29.8294C96.8768 27.3538 97.3158 25.0104 98.1938 22.7993C99.0717 20.5882 100.293 18.6701 101.858 17.0448C103.423 15.4196 105.341 14.1345 107.613 13.1896C109.903 12.2447 112.384 11.7723 115.056 11.7723C117.308 11.7723 119.427 12.103 121.412 12.7644C123.416 13.407 125.181 14.333 126.708 15.5424C128.235 16.7519 129.437 18.3016 130.315 20.1914C131.212 22.0812 131.661 24.1883 131.661 26.5128C131.661 27.6351 131.541 28.9132 131.301 30.3473C131.247 30.6681 130.96 30.8958 130.633 30.8813L110.264 29.9767C109.864 29.9589 109.539 30.3023 109.624 30.6921C109.963 32.2269 110.8 33.3945 112.136 34.1949C113.701 35.102 115.657 35.5556 118.005 35.5556C122.213 35.5556 125.912 34.4481 129.103 32.233C129.203 32.1637 129.322 32.1256 129.443 32.1256C129.767 32.1256 130.029 32.3865 130.029 32.7085V44.6007C130.029 44.828 129.911 45.0399 129.712 45.1522C128.29 45.9568 126.496 46.6226 124.332 47.1496C122.003 47.7165 119.465 48 116.717 48ZM110.461 25.8742C110.192 26.2761 110.529 26.767 111.013 26.742L119.012 26.33C119.442 26.3079 119.735 25.8803 119.511 25.5137C119.2 25.0029 118.765 24.5801 118.205 24.245C117.404 23.7348 116.421 23.4797 115.257 23.4797C114.111 23.4797 113.062 23.7631 112.107 24.3301C111.443 24.7118 110.894 25.2265 110.461 25.8742Z"
          fill="#6D092A"
        />
        <path
          d="M147.205 46.3842C150.049 47.4614 153.179 48 156.596 48C159.21 48 161.644 47.6409 163.896 46.9228C166.005 46.2561 167.694 45.516 168.963 44.7027C169.144 44.5867 169.25 44.3853 169.25 44.171V29.5557C169.25 29.2216 168.977 28.9507 168.642 28.9507C168.481 28.9507 168.327 29.0142 168.212 29.1262C166.273 31.016 164.424 32.3276 162.665 33.061C160.852 33.798 158.791 34.1666 156.481 34.1666C153.58 34.1666 151.29 33.5146 149.61 32.2106C147.931 30.9066 147.091 29.1586 147.091 26.9664C147.091 24.7742 147.921 23.0545 149.582 21.8072C151.242 20.5599 153.542 19.9363 156.481 19.9363C158.791 19.9363 160.842 20.3142 162.636 21.0701C164.396 21.7854 166.255 23.0971 168.212 25.005C168.327 25.1169 168.481 25.1805 168.642 25.1805C168.977 25.1805 169.25 24.9096 169.25 24.5754V9.79006C169.25 9.57579 169.144 9.37434 168.963 9.25837C167.694 8.44504 166.005 7.70501 163.896 7.03828C161.644 6.32015 159.21 5.96108 156.596 5.96108C153.179 5.96108 150.04 6.50913 147.177 7.60522C144.333 8.70131 141.957 10.1943 140.048 12.0841C138.14 13.9739 136.651 16.1944 135.582 18.7457C134.532 21.2969 134.007 24.0183 134.007 26.9097C134.007 29.8578 134.532 32.6264 135.582 35.2154C136.651 37.7856 138.14 40.0155 140.048 41.9053C141.976 43.7952 144.362 45.2881 147.205 46.3842Z"
          fill="#6D092A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.219 48C181.673 48 180.232 47.811 178.896 47.4331C177.579 47.0551 176.387 46.4881 175.318 45.7322C174.249 44.9763 173.409 43.9558 172.798 42.6707C172.188 41.3667 171.882 39.8643 171.882 38.1635C171.882 36.4627 172.216 34.9414 172.884 33.5996C173.571 32.2578 174.459 31.1712 175.547 30.3397C176.635 29.5082 177.933 28.8184 179.44 28.2703C180.967 27.7223 182.504 27.3443 184.05 27.1364C185.596 26.9097 187.227 26.7963 188.945 26.7963C190.892 26.7963 192.765 26.9263 194.564 27.1863C194.786 27.2184 194.986 27.0484 194.986 26.8256C194.986 26.769 194.973 26.7131 194.946 26.6632C194.056 25.0133 191.741 24.1883 188 24.1883C185.939 24.1883 183.821 24.5569 181.645 25.2939C179.558 25.9886 177.749 26.8778 176.216 27.9616C176.116 28.0327 175.996 28.0719 175.872 28.0719C175.55 28.0719 175.289 27.812 175.289 27.4915V15.3028C175.289 15.051 175.435 14.8212 175.665 14.7176C177.471 13.9062 179.559 13.2268 181.931 12.6794C184.489 12.0746 187.122 11.7723 189.833 11.7723C195.578 11.7723 200.13 13.2936 203.489 16.3362C206.848 19.3599 208.527 23.423 208.527 28.5255V47.264C208.527 47.6233 208.235 47.9147 207.874 47.9147H195.869C195.508 47.9147 195.215 47.6233 195.215 47.264V46.8092L196.89 32.3622C196.913 32.1761 196.767 32.0122 196.578 32.0122C196.409 32.0122 196.27 32.1471 196.263 32.3157C196.056 37.5305 194.838 41.4358 192.61 44.0314C190.338 46.6771 187.208 48 183.219 48ZM188.058 38.3053C189.737 38.3053 191.092 37.71 192.123 36.5194C193.036 35.4678 193.609 34.1805 193.844 32.6575C193.902 32.2813 193.599 31.9555 193.216 31.9555H191.35C188.964 31.9555 187.256 32.324 186.225 33.061C185.195 33.798 184.679 34.6863 184.679 35.7257C184.679 36.5572 185.004 37.1997 185.653 37.6533C186.302 38.0879 187.103 38.3053 188.058 38.3053Z"
          fill="#6D092A"
        />
        <path
          d="M224.512 47.9147C224.873 47.9147 225.166 47.6233 225.166 47.264V37.1997C225.166 33.4579 225.767 30.6704 226.97 28.8373C228.191 27.0042 229.842 26.0876 231.923 26.0876C233.388 26.0876 234.622 26.546 235.624 27.4629C235.731 27.5603 235.869 27.6184 236.013 27.6184C236.315 27.6184 236.56 27.3745 236.56 27.0738V12.423C236.56 12.0636 236.267 11.7707 235.907 11.7847C234.237 11.8495 232.785 12.1666 231.55 12.7361C230.176 13.3597 228.936 14.4463 227.829 15.996C226.741 17.5267 225.863 19.6244 225.195 22.2891C224.541 24.8962 224.052 28.1365 223.727 32.01C223.715 32.1551 223.593 32.2673 223.447 32.2673C223.28 32.2673 223.15 32.1226 223.168 31.9571L225.257 12.5516C225.3 12.1662 224.997 11.8292 224.608 11.8292H212.107C211.746 11.8292 211.453 12.1206 211.453 12.48V47.264C211.453 47.6233 211.746 47.9147 212.107 47.9147H224.512Z"
          fill="#6D092A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.718 48C255.779 48 253.059 47.537 250.559 46.611C248.059 45.6661 245.959 44.381 244.26 42.7558C242.581 41.1305 241.264 39.2029 240.31 36.9729C239.355 34.743 238.878 32.3618 238.878 29.8294C238.878 27.3538 239.317 25.0104 240.195 22.7993C241.073 20.5882 242.295 18.6701 243.86 17.0448C245.425 15.4196 247.343 14.1345 249.614 13.1896C251.904 12.2447 254.386 11.7723 257.058 11.7723C259.31 11.7723 261.428 12.103 263.413 12.7644C265.417 13.407 267.183 14.333 268.71 15.5424C270.236 16.7519 271.439 18.3016 272.317 20.1914C273.214 22.0812 273.662 24.1883 273.662 26.5128C273.662 27.6351 273.542 28.9132 273.302 30.3473C273.249 30.6681 272.961 30.8958 272.634 30.8813L252.266 29.9767C251.865 29.9589 251.54 30.3023 251.626 30.6921C251.964 32.2269 252.801 33.3945 254.137 34.1949C255.702 35.102 257.659 35.5556 260.006 35.5556C264.214 35.5556 267.913 34.4481 271.104 32.233C271.204 32.1637 271.323 32.1256 271.445 32.1256C271.768 32.1256 272.03 32.3865 272.03 32.7085V44.6007C272.03 44.828 271.912 45.0399 271.713 45.1522C270.291 45.9568 268.498 46.6226 266.333 47.1496C264.005 47.7165 261.466 48 258.718 48ZM252.462 25.8742C252.193 26.2761 252.53 26.767 253.014 26.742L261.013 26.33C261.444 26.3079 261.736 25.8803 261.513 25.5137C261.202 25.003 260.766 24.5801 260.207 24.245C259.405 23.7348 258.422 23.4797 257.258 23.4797C256.113 23.4797 255.063 23.7631 254.109 24.3301C253.444 24.7118 252.895 25.2265 252.462 25.8742Z"
          fill="#6D092A"
        />
        <path
          d="M39.5663 18.9903C39.0149 18.6679 38.2496 19.035 38.2496 19.6716V47.5844C38.2496 47.8139 38.4383 48 38.671 48H51.8858C52.1185 48 52.3072 47.8139 52.3072 47.5844V19.6926C52.3072 19.053 51.5341 18.6855 50.9808 19.0107C49.3259 19.983 47.4059 20.4344 45.3196 20.4344C43.2006 20.4344 41.251 19.9754 39.5663 18.9903Z"
          fill="#6D092A"
        />
        <path
          d="M39.718 16.0129C41.1518 17.3817 43.0193 18.0662 45.3208 18.0662C47.6035 18.0662 49.4428 17.3817 50.8387 16.0129C52.2536 14.644 52.961 12.9519 52.961 10.9366C52.961 8.94033 52.2442 7.24824 50.8104 5.86035C49.3956 4.45345 47.5657 3.75 45.3208 3.75C43.0571 3.75 41.1989 4.45345 39.7463 5.86035C38.3126 7.24824 37.5958 8.94033 37.5958 10.9366C37.5958 12.9329 38.3032 14.625 39.718 16.0129Z"
          fill="#6D092A"
        />
      </svg>
    )
  },
)
